import React from 'react';

import bnn from '../img/pics/bnn.png';
import amazon from '../img/pics/ordernow.png';
import instagram from '../img/pics/ig64.png';


function Footer () {
    return (
      <div className="footer pt-3 pb-2">
        
        <div className="container">
          <div className="row mb-2">
          
              <a className='col ml-auto mr-auto' target="_blank" rel="noreferrer" href="https://www.amazon.com/SOulfully-You-Chanel-Ray/dp/B0B6LNSN35/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1658790121&sr=8-1"><img src={amazon} alt="Amazon" /></a>
              <a className='col' target="_blank" rel="noreferrer" href="https://www.instagram.com/author_chanel_ray/"><img src={instagram} alt="Instagram" /></a>
              <a className='col ml-auto mr-auto' target="_blank" rel="noreferrer" href="https://www.barnesandnoble.com/w/soulfully-you-chanel-ray/1141816443?ean=9798765594391"><img src={bnn} alt="Barnes and Noble" /></a>
            
          </div>
         <span className="text-muted m-3">
            <b>Copyright © {new Date().getFullYear()} | Chanel Ray</b>
        </span>
        </div>
        <div className='footglass'></div>
      </div>
    )
  }

export default Footer;
