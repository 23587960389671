import React from "react";


// import logo from "../../src/img/pics/title.png";
import cover1pic from "../../src/img/pics/cover1.png";
import cover2pic from "../../src/img/pics/cover2.png";
import cover3pic from "../../src/img/pics/cover3.png";
import cover4pic from "../../src/img/pics/cover4.png";
import cover5pic from "../../src/img/pics/cover5.png";

function Home() {
  return (
    <div className="container pt-5">
        <h5 className="text-secondary p-4 ml-auto mr-auto pt-5">
          “SOulfully You” goes beyond the words on the page- creating a safe
          space through simplistic, yet imaginative meditatative practices for
          both children and adults alike. Grounding, re-centering, and exploring
          your “inner child” with your own… free yourself to be “SOulfully You.”
        </h5>
      <div className="row pt-3">
        <div className="col-2"></div>
        <div className="col-8 center coverspace relative">
          <div id="cover1key" className="cover1card">
            <img
              id="cover1pickey"
              className="cover1pic"
              src={cover1pic}
              alt="cover1"
            ></img>
          </div>
          <div id="cover2key" className="cover2card shadow-sm">
            <img
              id="cover2pickey"
              className="cover2pic"
              src={cover2pic}
              alt="cover2"
            ></img>
          </div>
          <div id="cover3key" className="cover3card">
            <img
              id="cover3pickey"
              className="cover3pic"
              src={cover3pic}
              alt="cover3"
            ></img>
          </div>
          <div id="cover4key" className="cover4card">
            <img
              id="cover4pickey"
              className="cover4pic"
              src={cover4pic}
              alt="cover4"
            ></img>
          </div>
          <div id="cover5key" className="cover5card">
            <img
              id="cover5pickey"
              className="cover5pic"
              src={cover5pic}
              alt="cover5"
            ></img>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
      <h5 className="text-secondary p-4 ml-auto mr-auto under">“SOulfully You” is about creating a safe and comforting space within any environment you or your child may find yourselves in. The meditative practices presented in this book encourage adults to tap into their own “inner child” and participate in the process especially during those challenging times when the child may be experiencing mental, emotional, or physical distress and need to feel a sense of safety and re-grounding. Readers are taken through a series of practices and directed to tap into their senses and identify various sounds, objects, and smells. Through these activities participants are able to explore their imagination, go to places of familiarity, safely push their comfort zones, and arrive at a renewed place of clarity and peace as a team - deepening their bond.

“SOulfully You” wants all readers to feel seen, felt, heard, understood, and loved no matter their background and feel free to show up authentically as themselves in every way- flowing through a variety of affirmations to round out the mindfulness exercises and bring the readers to a greater place of feeling more like their wonderful selves again.</h5>

<i>Note from the Author: I encourage you to be as patient with yourself and others as you can moving through the exercises, seek to understand, and step outside your comfort zone to connect with all that makes you “SOulfully You”!</i>
<br /><br />
    </div>
  );
}

export default Home;
