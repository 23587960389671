import React from "react";
import {Link} from 'react-router-dom';
import logo from '../img/pics/sigwide2.png';

import bnn from '../img/pics/bnn.png';
import amazon from '../img/pics/ordernow.png';
import instagram from '../img/pics/ig64.png';

function Nav() {
  return (

<nav class="navbar navbar-expand-lg bg-light fixed">
  <div class="container-fluid">
    <img src={logo} className="logo" alt="Chanel Ray"/>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="collapse navbar-collapse row" id="navbarSupportedContent">
      <ul class="navbar-nav mb-2 mb-lg-0 right">
        <li class="nav-item">
          <Link class="nav-link link right" aria-current="page" to="/">Home</Link>
        </li>
        <li class="nav-item">
          <Link class="nav-link link right"  aria-current="page" to="/about">About</Link>
        </li>
       
      </ul>

      
    </div>
    <div className="col-auto shrink right" id="buybtns">
      
            <a className='col' target="_blank" rel="noreferrer" href="https://www.instagram.com/author_chanel_ray/"><img src={instagram} alt="Instagram" /></a>
              <a className='col' target="_blank" rel="noreferrer" href="https://www.amazon.com/SOulfully-You-Chanel-Ray/dp/B0B6LNSN35/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1658790121&sr=8-1"><img src={amazon} alt="Amazon" /></a>
              <a className='col' target="_blank" rel="noreferrer" href="https://www.barnesandnoble.com/w/soulfully-you-chanel-ray/1141816443?ean=9798765594391"><img src={bnn} alt="Barnes and Noble" /></a>
        </div>
  </div>
  
</nav>



);
}
export default Nav;
