import React from "react";
import Home from '../pages/home'
import About from '../pages/about'

import {
  Routes,
  Route
} from "react-router-dom";


function Main() {
    return (
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/about" exact element={<About/>} />
      </Routes>
      
    );
  };

export default Main;