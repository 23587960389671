import React from 'react';
import Wrapper from './components/Wrapper';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import HttpsRedirect from 'react-https-redirect';

import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';



function App() {
  return (
    <div className="App">
     
      <div className="App-head">
      <HttpsRedirect>
          <BrowserRouter basename="/">
            <Wrapper>
                
                <div id="maincontent">
                <Header />
                  <Main />
                  <Footer />
                </div>
            </Wrapper>
          </BrowserRouter>
      </HttpsRedirect>
      </div>
      <div className='App-tail'>
        <div className="clouds fixed"></div>
        <div className='bodybkg fixed'></div>
        <div className="stars fixed"></div>
        <div className="twinkling fixed"></div>
      </div>
    </div>
  );
}

export default App;
