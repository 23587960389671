import React from 'react';
import Nav from './Navbar.js';

function Header () {
    return (
      <div>
        <Nav />
       </div>
    )
  }

export default Header;